import React, { useContext, useState, useCallback } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  Alert,
  Spinner,
  TabPane,
  TabContent,
} from "reactstrap";
import { withRouter, RouteComponentProps } from "react-router-dom";
import BEMHelper from "react-bem-helper";
import Pagination from "./Pagination";
import { Button } from "bokamera-embedded-ui";
import { useToasts } from "react-toast-notifications";

import useRequest from "../hooks/useRequest";
import api from "../utils/api";
import request from "../utils/request";
import { Booking, CustomerType } from "../types";
import CustomerForm from "./CustomerForm";
import AuthContext from "./AuthContext";
import BookingCard from "./BookingCard";
import CustomerCard from "./CustomerCard";
import ChangePasswordForm from "./ChangePasswordForm";
import { Trans } from "@lingui/react";
import BookingReviewTeasers from "./BookingReviewTeasers";
import keycloak from "@/keycloak";
import { readConfigurationProperty } from "@/utils/common";
import { useAppDispatch, useAppSelector } from "@/hooks/hook";
import { setCustomer } from "@/reducers/customer";
import qs from "qs";
import NewsletterSubscriptions from "./NewsletterSubscriptions";
import RebateCodeUsages from "./RebateCodeUsages";

const c = BEMHelper("BookingsPage");

const PAGE_SIZE = 10;

const now = new Date();

type CustomerProfileState = "view" | "edit" | "change-password";

const Bookings = ({ history }: RouteComponentProps) => {
  const customer = useAppSelector((s) => s.customer.data?.customer);
  const authoriseState = useAppSelector((s) => s.authorise);
  const configuration = useAppSelector((s) => s.configuration.data);
  const dispatch = useAppDispatch();
  const { addToast } = useToasts();

  const [profileState, setProfileState] =
    useState<CustomerProfileState>("view");
  const [tabIndex, setTabIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const {
    data: bookingsData,
    loading,
    loaded,
    update,
  } = useRequest(
    api.fetchBookings({
      Take: PAGE_SIZE,
      // BookingStart: tabIndex === 0 ? now.toISOString() : undefined,
      StatusIds: tabIndex === 1 ? [2, 3] : [1, 4, 5, 6, 7, 8, 9],
      Skip: currentPage * PAGE_SIZE,
    })
  );

  function onCancelBooking(bookingId: number) {
    return request(api.cancelBooking(bookingId))
      .then(() => {
        return update();
      })
      .catch((err) => {
        addToast(err.message, { appearance: "error" });
        return update();
      });
  }

  function onPayBooking(bookingId: number) {
    history.push(`/payment/${bookingId}`);
  }

  function onLogout() {
    clearSession(keycloak.clientId as string, keycloak.refreshToken as string)
      .then(() => {
        const targetOrigin = configuration?.targetOrigin;

        if (targetOrigin) {
          window.parent.postMessage({ logout: true }, targetOrigin as string);
          history.push("/login");
        }

        keycloak.logout().catch((err) => {
          console.log(err);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleChangeFilterByActive = useCallback(
    (tabIndex: number) => {
      setTabIndex(tabIndex);
      setCurrentPage(0);
    },
    [update]
  );
  const handlePageChange = useCallback(({ selected }) => {
    setCurrentPage(selected);
  }, []);

  if (authoriseState.isError) {
    return (
      <div {...c()}>
        <div {...c("center")}>
          <p>
            <Trans id="loginError" />
          </p>
          <Button {...c("button")} primary onClick={onLogout}>
            <Trans id="logOut"></Trans>
          </Button>
        </div>
      </div>
    );
  }

  if (loading || !customer) {
    return <Spinner color="secondary" {...c("spinner")} />;
  }

  const BookingsList = () => (
    <div {...c("listCol")}>
      {loaded && bookingsData && (
        <>
          {bookingsData.Results.map((booking: Booking) => (
            <BookingCard
              key={booking.Id}
              booking={booking}
              onCancelBooking={onCancelBooking}
              onPayBooking={onPayBooking}
            />
          ))}
          {bookingsData.Results.length !== 0 && (
            <Pagination
              pageCount={Math.ceil(bookingsData.Total / PAGE_SIZE)}
              pageRangeDisplayed={5}
              marginPagesDisplayed={3}
              forcePage={currentPage}
              onPageChange={handlePageChange}
            />
          )}
          {bookingsData.Results.length === 0 && (
            <Alert color="info">
              <Trans id="noBookingsFound"></Trans>
            </Alert>
          )}
        </>
      )}
    </div>
  );
  return (
    <div {...c()}>
      <div {...c("filters")}>
        <BookingReviewTeasers />
        <Nav pills {...c("nav")}>
          <NavItem
            onClick={(evt) => {
              evt.preventDefault();
              handleChangeFilterByActive(0);
            }}
          >
            <NavLink href="#" active={tabIndex === 0}>
              <Trans id="upcomingBookings"></Trans>
            </NavLink>
          </NavItem>
          <NavItem
            onClick={(evt) => {
              evt.preventDefault();
              handleChangeFilterByActive(1);
            }}
          >
            <NavLink href="#" active={tabIndex === 1}>
              <Trans id="inactiveBookings"></Trans>
            </NavLink>
          </NavItem>
          <NavItem
            onClick={(evt) => {
              evt.preventDefault();
              handleChangeFilterByActive(2);
            }}
          >
            <NavLink href="#" active={tabIndex === 2}>
              <Trans id="newsletterSubscriptions" />
            </NavLink>
          </NavItem>
          <NavItem
            onClick={(evt) => {
              evt.preventDefault();
              handleChangeFilterByActive(3);
            }}
          >
            <NavLink href="#" active={tabIndex === 3}>
              <Trans id="rebateCodeUsages" />
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <div {...c("content")}>
        <TabContent
          className="d-flex"
          style={{ gap: "20px" }}
          activeTab={String(tabIndex)}
        >
          <TabPane tabId={"0"} {...c("listCol")}>
            <BookingsList />
          </TabPane>
          <TabPane tabId={"1"} {...c("listCol")}>
            <BookingsList />
          </TabPane>
          <TabPane tabId={"2"} {...c("listCol")}>
            <div>
              {customer && profileState === "view" ? (
                <NewsletterSubscriptions />
              ) : null}
            </div>
          </TabPane>
          <TabPane tabId={"3"} {...c("listCol")}>
            <div>
              {customer && profileState === "view" ? (
               <RebateCodeUsages />
              ) : null}
            </div>
          </TabPane>
          <div {...c("customerCol")}>
            {customer && profileState === "edit" && (
              <CustomerForm
                onCancel={() => setProfileState("view")}
                onSubmitSuccess={(customer: any) => {
                  dispatch(setCustomer(customer));
                  setProfileState("view");
                }}
                initialValues={customer}
              />
            )}
            {customer && profileState === "change-password" && (
              <ChangePasswordForm
                email={customer.Email}
                onCancel={() => setProfileState("view")}
                onSubmitSuccess={(customer: any) => {
                  dispatch(setCustomer(customer));
                  setProfileState("view");
                }}
                initialValues={{ Email: customer.Email }}
              />
            )}
            {customer && profileState === "view" && (
              <CustomerCard
                onEdit={() => setProfileState("edit")}
                onChangePassword={() => setProfileState("change-password")}
                onLogout={onLogout}
                customer={customer}
              />
            )}
          </div>
        </TabContent>
      </div>
    </div>
  );
};

async function clearSession(clientId: string, refreshToken: string) {
  var myHeaders = new Headers();
  var urlencoded = new URLSearchParams();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  urlencoded.append("refresh_token", refreshToken);
  urlencoded.append("client_id", clientId);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
  };

  return fetch(
    `${process.env.REACT_APP_KEYCLOAK_AUTH_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/logout`,
    requestOptions
  );
}

export default withRouter(Bookings);
